.card-img-overlay {
  &::before {
    position: absolute;
    content: "";
    background: rgba($color: $black, $alpha: .3);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.card-content {
  padding: 30px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}

.title-border {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 30px;

  &::before {
    position: absolute;
    content: "";
    height: 2px;
    width: 100px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    background: $border-color;
  }
}

.title-border-lg {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 2px;
    width: 300px;
    left: -45px;
    bottom: -50px;
    background: $border-color;
  }
}

.pagination {
  @include mobile {
    overflow: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent
    }
  }

  .page-item {
    .page-link {
      font-family: $secondary-font;
      font-size: 30px;
      border: 0;
      color: $text-color;

      @include tablet {
        font-size: 16px;
      }

      &:hover {
        color: $text-color-dark;
        background-color: transparent;
      }
    }

    &:first-child,
    &:last-child {
      .page-link {
        font-size: 16px;
      }
    }

    &.active {
      .page-link {
        color: $text-color-dark;
        text-decoration: underline;
        background: transparent;
      }
    }
  }
}

.instagram-post {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba($color: $black, $alpha: .5);
    opacity: 0;
    visibility: hidden;
    transition: .2s ease;
  }

  ul {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: .2s ease;

    a {
      transition: .2s ease;
      font-size: 20px;

      &:hover {
        color: $primary-color !important;
      }
    }
  }

  &:hover {
    &::before {
      visibility: visible;
      opacity: 1;
    }

    ul {
      visibility: visible;
      opacity: 1;
    }
  }
}

/* homepage 2 */
.hero-section {
  padding: 70px 0 0;
  background-image: linear-gradient(#fff 45%, $secondary-color 0);
}

.dividers {
  display: flex;
  align-items: center;
  width: 1200px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .divider {
    border-right: 1px solid #edeef0;
    flex-basis: 50%;
    height: 100vh;
    opacity: .6;

    &:first-child {
      border-left: 1px solid #edeef0;
    }
  }
}

.article-full-width {
  display: flex;
  flex-wrap: nowrap;

  @include tablet {
    flex-direction: column;
  }

  .post-image {
    margin-right: 20px;

    @include tablet {
      margin: 0 0 20px 0;
    }

    img {
      height: 400px;
      width: auto;

      @include tablet {
        height: auto;
        width: 100%;
      }
    }
  }

  .post-content {
    width: 50%;

    @include tablet {
      width: 100%;
    }

    .btn {
      padding-left: 0;
    }
  }

  .post-meta {
    width: 250px;
  }

  .post-summary {
    @include desktop {
      display: none;
    }

    @include tablet {
      display: block;
    }
  }

  &.article-right {
    justify-content: flex-end;

    @include tablet {
      flex-direction: column-reverse;
    }

    .post-image {
      order: 2;
      margin: 0 0 0 20px;

      @include tablet {
        margin: 0 0 20px 0;
      }
    }

    .post-content {
      order: 1;
    }

    .post-meta {
      margin-left: auto;

      @include tablet {
        margin-left: 0;
      }
    }

    .author {
      text-align: right;

      @include tablet {
        text-align: left;
      }
    }
  }
}